/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import {
  FiDollarSign,
  FiPlusCircle,
  FiMinusCircle,
  FiPercent,
} from 'react-icons/fi'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import { ValorMascarar } from '../../utils/masks'
import { formatValue } from '../../utils/formatValues'

import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import {
  Container,
  Content,
  UPEContent,
  UPEBox,
  ButtonController,
  InfoContent,
  CheckNoContrib,
  Line,
  BtnVoltar,
} from './styles'
import { UserDetails } from '../../utils/interfaces'

const Contribution: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [UPE] = useState(1498.28) // (1432.38) (1375.44)
  const [tipo] = useState(
    userDetails.tipoContribuicaosuplementar === 'F' ? 'F' : 'P',
  )
  const [salarioValue, setSalarioValue] = useState(userDetails.salario)
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [, setVlrCtbTotal] = useState(userDetails.contribuicaoBasica * 2)
  const [txtPct1] = useState(1)

  const [txtPct2, setTxtPct2] = useState(
    userDetails.pctContribuicaoBasicaFaixa2 > 0
      ? userDetails.pctContribuicaoBasicaFaixa2
      : 7,
  )
  const [txtPct3, setTxtPct3] = useState(
    userDetails.pctContribuicaoBasicaFaixa3 > 0
      ? userDetails.pctContribuicaoBasicaFaixa3
      : 9.5,
  )
  const [pesoUPE, setPesoUPE] = useState(
    userDetails.salario > 0 ? userDetails.salario / UPE : 0,
  )
  const [valorFaixa1, setValorFaixa1] = useState(
    userDetails.vlrFaixa1 > 0 ? userDetails.vlrFaixa1 : 0,
  )
  const [valorFaixa2, setValorFaixa2] = useState(
    userDetails.vlrFaixa2 > 0 ? userDetails.vlrFaixa2 : 0,
  )
  const [valorFaixa3, setValorFaixa3] = useState(
    userDetails.vlrFaixa3 > 0 ? userDetails.vlrFaixa3 : 0,
  )
  const [vis2, setVis2] = useState(false)
  const [pctCtbSup, setPctCtbSup] = useState(
    userDetails.pctContribuicaoSuplementar > 0
      ? userDetails.pctContribuicaoSuplementar
      : 0,
  )
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleMudaContrib = useCallback(
    (pct, sal) => {
      const parcelaUPE = sal / UPE
      if (parcelaUPE > 5.0 && parcelaUPE <= 15.0) {
        const faixa1 = UPE * 5 * 0.01
        const residual1 = sal - UPE * 5
        const faixa2 = residual1 * (pct / 100)
        const valueC = faixa1 + faixa2
        setValorFaixa1(faixa1)
        setValorFaixa2(faixa2)
        setVlrCtbBasica(valueC)
        setVlrCtbTotal(2 * valueC)
        setTxtPct2(pct)
      } else {
        const faixa1 = UPE * 5 * 0.01
        const residual1 = UPE * 15.0 - UPE * 5
        const faixa2 = residual1 * (txtPct2 / 100)
        const residual2 = sal - UPE * 15
        const faixa3 = residual2 * (pct / 100)
        const valueC = faixa1 + faixa2 + faixa3
        setValorFaixa1(faixa1)
        setValorFaixa2(faixa2)
        setValorFaixa3(faixa3)
        setVlrCtbBasica(valueC)
        setVlrCtbTotal(2 * valueC)
        setTxtPct3(pct)
      }
    },
    [UPE, txtPct2],
  )

  const handleMudaContribF = useCallback(
    (pct, sal, ff) => {
      // const parcelaUPE = sal / UPE
      // const valor5upe = UPE * 5
      const valor15upe = UPE * 15
      if (ff === 1) {
        const faixa1 = sal * 0.01
        setValorFaixa1(faixa1)
        setVlrCtbBasica(faixa1)
        setVlrCtbTotal(faixa1 * 2)
      } else if (ff === 2) {
        // const faixa1 = UPE * 5 * 0.01
        const residual1 = (sal > valor15upe ? valor15upe : sal) - UPE * 5
        const faixa2 = residual1 * (pct / 100)
        const valueC = valorFaixa1 + faixa2 + valorFaixa3
        setValorFaixa2(faixa2)
        setVlrCtbBasica(valueC)
        setVlrCtbTotal(2 * valueC)
        setTxtPct2(pct)
      } else {
        // const faixa1 = UPE * 5 * 0.01
        // const residual1 = UPE * 15.0 - UPE * 5
        // const faixa2 = residual1 * 0.07
        const residual2 = sal - UPE * 15
        const faixa3 = residual2 * (pct / 100)
        const valueC = valorFaixa1 + valorFaixa2 + faixa3
        setValorFaixa3(faixa3)
        setVlrCtbBasica(valueC)
        setVlrCtbTotal(2 * valueC)
        setTxtPct3(pct)
      }
    },
    [UPE, valorFaixa1, valorFaixa2, valorFaixa3],
  )

  const optionsArraySup = []
  for (let i = 1; i <= 50; i += 1) {
    optionsArraySup.push({ label: `${i} %`, value: i })
  }

  const minusContribF = useCallback(
    faixa => {
      handleMudaContribF(
        (faixa === 2 ? txtPct2 : txtPct3) - 0.5,
        salarioValue,
        faixa,
      )
    },
    [handleMudaContribF, salarioValue, txtPct2, txtPct3],
  )

  const plusContribF = useCallback(
    faixa => {
      handleMudaContribF(
        (faixa === 2 ? txtPct2 : txtPct3) + 0.5,
        salarioValue,
        faixa,
      )
    },
    [handleMudaContribF, salarioValue, txtPct2, txtPct3],
  )

  const handleCalculaContribuicao = useCallback(
    sal => {
      setVlrCtbBasica(0)
      setSalarioValue(sal)
      if (sal !== null && sal > 0) {
        const parcelaUPE = sal / UPE
        setPesoUPE(parcelaUPE)
        if (parcelaUPE > 0 && parcelaUPE <= 5.0) {
          const valueC = sal * 0.01
          setValorFaixa1(valueC)
          setVlrCtbBasica(valueC)
          setVlrCtbTotal(2.0 * valueC)
        } else if (parcelaUPE > 5.0 && parcelaUPE <= 15.0) {
          handleMudaContrib(txtPct2, sal)
        } else {
          handleMudaContrib(txtPct3, sal)
        }
      }
    },
    [UPE, handleMudaContrib, txtPct2, txtPct3],
  )

  const handleMudaContribSup = useCallback(
    valor => {
      if (valor < 0 || valor > 50) {
        // eslint-disable-next-line no-alert
        alert(
          'O percentual da contribuição voluntária deve estar entre 0 e 50%!',
        )
      } else {
        const valueCSup = (salarioValue * valor) / 100
        setPctCtbSup(valor)
        setVlrCtbSup(valueCSup)
      }
    },

    [salarioValue],
  )

  const mudarSalario = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v)) {
        setSalarioValue(salarioValue)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setSalarioValue(f)
        handleCalculaContribuicao(f)

        pctCtbSup > 0 && handleMudaContribSup(0)
      }
    },
    [salarioValue, handleCalculaContribuicao, pctCtbSup, handleMudaContribSup],
  )

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasica: vlrCtbBasica,
          pctContribuicaoBasica: txtPct1,
          pctContribuicaoBasicaFaixa2: pesoUPE <= 5.0 ? 0 : txtPct2,
          pctContribuicaoBasicaFaixa3: pesoUPE <= 15.0 ? 0 : txtPct3,
          contribuicaoSuplementar: vlrCtbSup,
          tipoContribuicaosuplementar: tipo,
          pctContribuicaoSuplementar: pctCtbSup,
          vlrFaixa1: valorFaixa1,
          vlrFaixa2: valorFaixa2,
          vlrFaixa3: valorFaixa3,
        })

        // history.push('/taxation')
        history.push('/ppe-fatca')
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      history,
      pctCtbSup,
      pesoUPE,
      salarioValue,
      setUserDetails,
      tipo,
      txtPct1,
      txtPct2,
      txtPct3,
      userDetails,
      valorFaixa1,
      valorFaixa2,
      valorFaixa3,
      vlrCtbBasica,
      vlrCtbSup,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            salario:
              userDetails.salario === undefined
                ? ''
                : ValorMascarar(userDetails.salario.toFixed(2).toString()),
            pctContribuicaoSup: userDetails.pctContribuicaoSuplementar,
          }}
        >
          <Content>
            <strong>Contribuição básica</strong>
            <p>
              A contribuição básica é realizada mensalmente, com desconto na
              folha de pagamento, 12 (doze) vezes ao ano. O cálculo é feito com
              base no percentual escolhido pelo Participante, tomando por base a
              parcela de salário aplicável calculada pela UPE. Tendo como base a
              contribuição calculada a empresa fará uma contribuição de igual
              valor.
            </p>
            <br />
            <p>
              Os percentuais da Contribuição Básica poderão ser alterados pelo
              Participante a qualquer tempo, respeitando o intervalo mínimo de 6
              (seis) meses a cada alteração.
            </p>
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário R$?"
              onChange={e => mudarSalario(e.target.value)}
            />

            <UPEContent>
              {pesoUPE > 0 ? (
                <UPEBox>
                  <p>Inferior a 5 UPEs</p>
                  <div>
                    <ButtonController type="button" disabled>
                      <FiMinusCircle color="transparent" />
                    </ButtonController>
                    <strong>1%</strong>
                    <ButtonController type="button" disabled>
                      <FiPlusCircle color="transparent" />
                    </ButtonController>
                  </div>
                  <strong>R$ {ValorMascarar(valorFaixa1.toFixed(2))}</strong>
                </UPEBox>
              ) : (
                <></>
              )}
              {pesoUPE > 5 ? (
                <UPEBox>
                  <p>De 5 a 15 UPEs</p>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={txtPct2 <= 1}
                      isSelected={txtPct2 > 1}
                      onClick={() => minusContribF(2)}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{txtPct2}%</strong>
                    <ButtonController
                      type="button"
                      disabled={txtPct2 >= 7}
                      isSelected={txtPct2 < 7}
                      onClick={() => plusContribF(2)}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                  <strong>R$ {ValorMascarar(valorFaixa2.toFixed(2))}</strong>
                </UPEBox>
              ) : (
                <></>
              )}
              {pesoUPE > 15 ? (
                <UPEBox>
                  <p>Acima de 15 UPEs</p>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={txtPct3 <= 1}
                      isSelected={txtPct3 > 1}
                      onClick={() => minusContribF(3)}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{txtPct3}%</strong>
                    <ButtonController
                      type="button"
                      disabled={txtPct3 >= 9.5}
                      isSelected={txtPct3 < 9.5}
                      onClick={() => plusContribF(3)}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                  <strong>R$ {ValorMascarar(valorFaixa3.toFixed(2))}</strong>
                </UPEBox>
              ) : (
                <></>
              )}
            </UPEContent>

            <Line />

            <InfoContent>
              <div>
                <p>Valor da contribuição mensal básica do Participante: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <p>Valor da contribuição mensal básica da Patrocinadora: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <div>
                  <p>Valor total de contribuição mensal básica ao Plano: </p>
                  <small>(participante + patrocinadora)</small>
                </div>
                <strong>{formatValue(vlrCtbBasica * 2)}</strong>
              </div>
              <span>Valor UPE: {formatValue(UPE)} (Nov/2023 a Out/2024)</span>
            </InfoContent>
          </Content>
          <Content>
            <strong>Contribuição voluntária</strong>
            <p>
              Para contribuir ainda mais com a formação da sua reserva, cadastre
              uma contribuição voluntária.
            </p>
            <Input
              type="number"
              icon={FiPercent}
              name="pctContribuicaoSup"
              value={pctCtbSup}
              onChange={e => handleMudaContribSup(e.target.value)}
              placeholder="Com qual percentual você quer contribuir"
            />
            <small>
              A contribuição voluntária também será descontada da sua folha de
              pagamento mensalmente, deste valor não há contrapartida da
              empresa.
            </small>

            <Line />

            <InfoContent>
              <div>
                <p>Valor da contribuição mensal voluntária: </p>
                <strong>{formatValue(vlrCtbSup)}</strong>
              </div>
            </InfoContent>

            <CheckNoContrib>
              <input
                type="checkbox"
                checked={vis2}
                onChange={e => {
                  setVis2(e.target.checked)
                  setPctCtbSup(0)
                  handleMudaContribSup(0)
                }}
              />
              <span>Não quero cadastrar uma contribuição voluntária</span>
            </CheckNoContrib>

            <small>
              Está em dúvida? Não se preocupe, depois você poderá alterar estes
              valores sempre que desejar.
            </small>
          </Content>
        </Form>

        <Button
          type="submit"
          color="greenBlue"
          onClick={handleClick}
          disabled={
            vlrCtbBasica <= 0 ||
            (txtPct2 === 7 &&
              txtPct3 === 9.5 &&
              salarioValue >= UPE * 15 &&
              !vis2 &&
              vlrCtbSup <= 0)
          }
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Contribution
